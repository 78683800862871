export class Environment {
  
    static getApiUrl() {
    
    return 'https://sapi.grupoaml.org/api/v2';

    // return 'http://localhost:8080/api/v2';

  }


}