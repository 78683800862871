import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import NavigationBar from "../segments/navigationBar";
import './Player.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHeart, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Environment } from '../../context/Environment';
import Plyr from "plyr-react"
import "plyr-react/plyr.css"

const Player = () => {

    const { video } = useParams();
    
    const [sourceVideo, setSource] = useState(null);

    const [movie, setMovie] = useState(null)

    const [isVideo, setModType] = useState(null);

    const [showTitle, setShowTitle] = useState(true);

    const videoRef = useRef<HTMLVideoElement>(null);

    const [isCompleted, setCompleted] = useState(false);

    const handleVideoEnd = async () => {

        try {
            const token = localStorage.getItem("token_habdi010924");

            const paylo = JSON.parse(token);

            const respo = await fetch(Environment.getApiUrl() + '/history/' + video, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-api-key': 'Bearer ' + paylo.token
                }
            });

            const { ok, data } : any = await respo.json();
            
            if(ok == true) {
                setCompleted(true);
            }

        } catch (e) {
            console.log(e);
        }
    };

    async function sendRanking(arg0: number): Promise<void> {

        const response = await fetch(Environment.getApiUrl() + `/ranking/${video}/${arg0}`);

        const { ok }: any = await response.json();

        if (ok == true) {
            toast.success('¡Gracias por tu contribución!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    }

    useEffect(() => {

        const fetchMovies = async () => {

            const token = localStorage.getItem("token_habdi010924");

            const paylo: any = JSON.parse(token);

            const response = await fetch(Environment.getApiUrl() + '/track/' + video, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-api-key': 'Bearer ' + ( paylo != null ? paylo.token : '')
                }
            });

            const { data }: any = await response.json();

            setMovie(data);

            setSource({
                type: data.type,
                title: data.title,
                sources: [
                  {
                    src: data.location,
                    type: 'video/mp4',
                    size: 720,
                  },
                ],
                poster: data.thumb,

            });

            setShowTitle(data.title);

            setCompleted(data.completed);

            if (data.type == 'video') {
                setModType(true);
            }

        }

        fetchMovies();

        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.addEventListener('ended', handleVideoEnd);
        }

        // Limpieza del event listener
        return () => {
            if (videoElement) {
                videoElement.removeEventListener('ended', handleVideoEnd);
            }
        };

    }, []);


    return (
        <div className="text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main className="bg-black h-screen">

                {isCompleted ? (

                    <div className="fixed w-full bottom-0 fixed mx-auto flex flex-col px-5 py-5 items-center justify-center rounded bg-gray-100" style={{zIndex:2}}>
                        <p>
                            <FontAwesomeIcon icon={faCheck} /> Este video ya fue visto en su totalidad, deseas obtener tu constancia ?
                        </p>

                        <Link to="mailto:habdi.net.2@gmail.com" className="font-bold text-blue"> Click aquí </Link>

                    </div>

                ) : ([])}

                {movie ? (

                    <h1 className="mx-auto flex flex-col px-5 py-5 items-center justify-left rounded bg-gray-200">                        
                        Estas viendo: {movie.title}
                    </h1>

                ): ([])}

                {movie && sourceVideo ? (


                    <div className="relative h-3/4 w-full flex flex-col items-center justify-center bg-black">
                        
                        <Plyr source={sourceVideo} onEnded={handleVideoEnd} />

                        <div className="absolute top-4 right-4 flex space-x-4">
                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(1)}>
                                <FontAwesomeIcon icon={faThumbsUp} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(2)}>
                                <FontAwesomeIcon icon={faHeart} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(3)}>
                                <FontAwesomeIcon icon={faThumbsDown} className="text-gray-700" />
                            </button>
                        </div>

                    </div>

                ) : ([])}




            </main>

            <ToastContainer />

        </div>
    )

}

export default Player;